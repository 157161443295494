<template>
<div :class="className" ref="charts" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/shine') // echarts theme

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '250px'
    },
    order_info: Array
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.initChart()
    }, 100);

  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  watch: {
    order_info: {
      handler(nval, oval) {
        if (nval) {
          let times = [];
          let datas = [];
          for (let i = 0; i < nval.length; i++) {
            let item = nval[i];
            times.push(item.OrderDate);
            datas.push(item.total);
          }
          this.mycharts && this.mycharts.setOption({
            title: {
              text: "月度订单数量趋势",
            },
            xAxis: {
              data: times,
            },
            series: [
              {
                data: datas,
              },
            ],
          });
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    initChart() {
      this.mycharts = echarts.init(this.$refs.charts,'shine');
      this.mycharts.setOption({
        title: {
          text: "月度订单数量趋势",
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          min: 0
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: "30%",

            //   showBackground: true,
            //   backgroundStyle: {
            //     color: "rgba(180, 180, 180, 0.2)",
            //   },
          },
        ],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
      });
    }
  }
}
</script>
